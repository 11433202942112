import React, { Component } from 'react'
import { Router, Route, Switch, withRouter, Redirect } from 'react-router-dom'
import qs from 'query-string'
import Loadable from 'react-loadable'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider'
import ReactPixel from 'react-facebook-pixel'
import iziToast from 'izitoast'
import theme from './theme'
import SandHeader from '../../components/SandHeader/SandHeader'
import Loading from '../../components/Loading'
import { PIXEL_ID } from '../../constants/config'
import { isExpired, saveLogTime, getUser } from '../../utils/localStorageUtil'
import authAction from '../../redux/auth/actions'
import sandboxActions from '../../redux/sandbox/actions'
import SandboxPublic from '../SandboxPublic'
import './Root.scss'
import 'izitoast/dist/css/iziToast.min.css'

const { logoutRequest } = authAction
const { sandOAuthToken } = sandboxActions

iziToast.settings({
	position: 'bottomLeft',
	maxWidth: '400px'
})

const NotFound = Loadable({
	loader: () => import('../Error404'),
	loading: Loading
})

const SandboxLogin = Loadable({
	loader: () => import('../SandboxLogin/SandboxLogin'),
	loading: Loading
})
const SandboxSignup = Loadable({
	loader: () => import('../SandboxSignup/SandboxSignup'),
	loading: Loading
})
const SandboxProfile = Loadable({
	loader: () => import('../SandboxProfile/SandboxProfile'),
	loading: Loading
})

const ValidateSandUser = Loadable({
	loader: () => import('../ValidateSandUser/ValidateSandUser'),
	loading: Loading
})

const VerifySandEmail = Loadable({
	loader: () => import('../VerifySandEmail/VerifySandEmail'),
	loading: Loading
})

const UserConfirm = Loadable({
	loader: () => import('../UserConfirm/UserConfirm'),
	loading: Loading
})

const PasswordReset = Loadable({
	loader: () => import('../PasswordReset/PasswordReset'),
	loading: Loading
})

const ConfirmPasswordReset = Loadable({
	loader: () => import('../ConfirmPasswordReset/ConfirmPasswordReset'),
	loading: Loading
})

const Logout = Loadable({
	loader: () => import('../Logout'),
	loading: Loading
})

const isAssessmentPage = path => {
	const assessmentPageList = ['user', 'sb', 'profile']
	for (const pageName of assessmentPageList) {
		if (path.includes(pageName)) {
			return true
		}
	}
	return false
}

const isSandboxPage = path => {
	const sPageList = ['login-talent', 'signup-talent']
	for (const pageName of sPageList) {
		if (path.includes(pageName)) {
			return true
		}
	}
	return false
}

class Root extends Component {
	// NOTE: needed for getting the token in query string for oauth
	componentWillMount() {
		const { token } = qs.parse(window.location.search)
		if (token) {
			const { sandOAuthToken } = this.props
			sandOAuthToken(token)
		}
	}

	componentDidMount() {
		const shareURL = `${window.location.hostname}`
		if (shareURL !== 'nuggetai.com') {
			return
		}
		ReactPixel.init(PIXEL_ID, {}, { debug: false, autoConfig: true })
		ReactPixel.pageView()
		ReactPixel.fbq('track', 'PageView')
	}

	componentDidUpdate(prevProps) {
		if (this.props.location !== prevProps.location) {
			this.handleCheck()
		}
	}

	handleCheck() {
		const { logoutRequest } = this.props
		if (isExpired()) {
			logoutRequest()
			this.props.history.push('/login-talent')
		} else {
			saveLogTime()
		}
	}

	render() {
		const { history, user, isLoggedIn } = this.props
		const isAssessment = isAssessmentPage(history.location.pathname)
		const isSandbox = isSandboxPage(history.location.pathname)
		const rootStyle = isAssessment ? 'test_container' : 'root__container'

		const urlParams = new URLSearchParams(window.location.search)
		const assessId = urlParams.get('assessId')
		const pipelineId = urlParams.get('pipelineId')

		console.log('assessId', assessId)
		console.log('pipelineId', pipelineId)

		if (assessId && pipelineId) {
			localStorage.setItem('assessId', assessId)
			localStorage.setItem('pipelineId', pipelineId)
		}

		return (
			<MuiThemeProvider theme={theme}>
				<Router history={history}>
					<div className="wrapper">
						{isSandbox && <SandHeader />}
						<div className={rootStyle}>
							<Switch>
								<Route
									path="/assessment/*"
									render={() => {
										if (!isLoggedIn) {
											iziToast.info({
												message: 'Please login to take the assessment',
												position: 'topCenter'
											})
											return <Redirect to="/login-talent" />
										}
										return <Redirect to="/user/assessments/" />
									}}
								/>
								<Route
									exact
									path="/"
									render={() => {
										if (!isLoggedIn) return <Redirect to="/login-talent" />
										return <Redirect to="/user/" />
									}}
								/>
								<PrivateRoute
									isLoggedIn={isLoggedIn}
									path="/user"
									component={SandboxProfile}
								/>
								<PrivateRoute
									isLoggedIn={isLoggedIn}
									path="/user/*"
									component={SandboxProfile}
								/>
								<Route path="/logout" component={Logout} />
								<Route path="/login-talent" component={SandboxLogin} />
								<NotAuthRoute
									isLoggedIn={isLoggedIn}
									path="/signup-talent"
									component={SandboxSignup}
								/>
								<Route path="/profile/:username" component={SandboxPublic} />
								<Route exact path="/verify-sanduser/:token" component={ValidateSandUser} />
								<Route exact path="/password-reset" component={PasswordReset} />
								<Route
									exact
									path="/password-reset/:token"
									component={ConfirmPasswordReset}
								/>
								<Route path="/userConfirm/:token" component={UserConfirm} />
								<Route
									exact
									path="/verify-sandbox-email/:sand_id/:sand_email"
									component={VerifySandEmail}
								/>
								<Route component={NotFound} />
							</Switch>
						</div>
					</div>
				</Router>
			</MuiThemeProvider>
		)
	}
}

const PrivateRoute = ({ isLoggedIn, ...props }) => {
	if (!isLoggedIn) return <Redirect to="/login-talent" />
	return <Route {...props} />
}
const NotAuthRoute = ({ isLoggedIn, ...props }) => {
	if (isLoggedIn) return <Redirect to="/user/" />
	return <Route {...props} />
}

Root.propTypes = {
	isLoggedIn: PropTypes.bool.isRequired,
	history: PropTypes.object.isRequired,
	logoutRequest: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
	isLoggedIn: state.authReducer.isLoggedIn,
	user: state.sandboxReducer.sand_data
})

const mapDispatchToProps = {
	logoutRequest,
	sandOAuthToken
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Root))
